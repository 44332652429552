<template>
  <transportation-profile-component @update-title="changeTitle" />
</template>

<script>
import TransportationProfileComponent from "@/components/transports/TransportationProfileComponent";

export default {
  name: "TransportationProfile",
  title: "Perfil del Transporte | Baja California Health Tourism",
  components: { TransportationProfileComponent },
  methods: {
    changeTitle(e) {
      this.title = e;
      document.title = `${e} | Baja California Health Tourism`;
    }
  }
};
</script>

<style scoped></style>
